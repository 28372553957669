<template>
    <div class="app-stage-customer-details">
        <app-radio-buttons
            v-model="customer_type"
            :items="customer_types"
            :inline="true"
        />
        <component 
            :is="customer_types[customer_type].component"
            :defaultcustomer="defaultcustomer"
            @set-customer="onSetCustomer"
        ></component>
    </div>
</template>

<script>
import appRadioButtons from '@/components/app-radio-buttons'

import newCustomer from './new-customer.vue'
import existingCustomer from './existing-customer.vue'

export default {
    components: {
        appRadioButtons,
    },

    data() {
        return {
            defaultcustomer: null,

            customer_type: 'new-customer',

            customer_types: {
                'new-customer': {
                    value: 'new-customer',
                    label: 'New Customer',
                    component: newCustomer,
                },
                'existing-customer': {
                    value: 'existing-customer',
                    label: 'Existing Customer',
                    component: existingCustomer,
                },
            },
        }
    },

    methods: {
        init() {},

        onSetCustomer(customer) {
            this.defaultcustomer = customer
            this.customer_type = 'existing-customer'
            this.$emit('ready', { customer })
            this.$emit('go-next')
        },
    },
}
</script>