<template>
    <div class="stage-existing-customer">
        <div class="form">
            <div>
                <app-autocomplete
                    label="Customer search"

                    :options="options"
                    :delay="250"
                    :maxdelay="500"
                    :initialvalue="customer_as_option"

                    @typing="onTyping"
                    @select="onSelect"
                />
            </div>

            <div>
                <app-input v-model="customer.UUID" label="Customer Account UUID" disabled />
            </div>
        </div>

        <button class="btn btn-primary btn-small" :disabled="!customer.UUID" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appAutocomplete from '@/components/app-autocomplete'
import appInput from '@/components/app-input'

export default {
    components: {
        appAutocomplete,
        appInput,
    },

    props: {
        defaultcustomer: { type: Object, default: null },
    },

    data() {
        return {
            customer: {},

            options: [],
        }
    },

    mounted() {
        if (this.defaultcustomer && this.defaultcustomer.UUID && (!this.customer || !this.customer.UUID)) {
            this.customer = this.defaultcustomer
        }
    },

    methods: {
        confirm() {
            this.$emit('set-customer', this.customer)
        },

        composeCustomerName(customer) {
            if (!customer) {
                return ''
            }
            let val = ''
            if (customer.FirstName) {
                val = `${val} ${customer.FirstName}`
                val = val.trim()
            }
            if (customer.LastName) {
                val = `${val} ${customer.LastName}`
                val = val.trim()
            }
            if (customer.Email) {
                val = `${val} (${customer.Email})`
                val = val.trim()
            }
            if (customer.ExternalRef1) {
                val = `${val} [${customer.ExternalRef1}]`
                val = val.trim()
            }
            return val
        },

        onTyping(value) {
            const params = {
                SPID: this.current_spid,

                SmartSearch: value,

                'SearchOptions.PageSize': 10,
            }

            this.$store.dispatch('api_customer/FindCustomersPaginated', params)
                .then(response => {
                    if (!response.Customers || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.options = response.Customers.map(customer => {
                        return {
                            key: customer.UUID,
                            value: this.composeCustomerName(customer),
                            ...customer,
                        }
                    })
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.options = []
                })
        },

        onSelect(option) {
            const customer = { ...option }

            delete customer.key
            delete customer.value

            this.customer = customer
        },
    },
    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        customer_as_option() {
            return {
                key: (this.pick_customer && this.pick_customer.UUID) ? this.pick_customer.UUID : '',
                value: this.composeCustomerName(this.pick_customer),
                ...this.pick_customer
            }
        },

        pick_customer() {
            return (this.customer && this.customer.UUID) ? this.customer : ((this.defaultcustomer && this.defaultcustomer.UUID) ? this.defaultcustomer : this.customer)
        }
    },

}
</script>

<style lang="scss">
.stage-existing-customer {
    padding: 30px 0;

    .form {
        display: flex;

        & > div {
            &:nth-child(1) {
                width: 100%;
                max-width: 660px;
                margin-right: 30px;
            }

            &:nth-child(2) {
                width: 100%;
                max-width: 370px;
            }
        }
    }

    .btn-primary {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .stage-existing-customer {
        .form {
            flex-direction: column;

            & > div {
                &:nth-child(1) {
                    max-width: 100%;
                    margin: 0;
                }

                &:nth-child(2) {
                    max-width: calc(50% - 15px);
                    margin-top: 30px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-existing-customer {
        .form {
            & > div {
                &:nth-child(2) {
                    max-width: 100%;
                    margin-top: 24px;
                }
            }
        }

        .btn-primary {
            margin-top: 24px;
        }
    }
}
</style>