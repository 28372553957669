<template>
    <div class="stage-summary">
        <app-loader v-if="loading" />

        <app-error v-model="error.show" :message="error.message" />

        <dialog-broadband-add-success
            v-if="dialogs.success.show"
            v-model="dialogs.success.show"

            @closes="onSuccessCloses"
        />

        <div class="summary">

            <div class="line">
                <div class="key">Customer:</div>
                <div class="value">{{ first_name }} {{ last_name }}<span v-if="email"> ({{ email }})</span><span v-if="mobile_number">, Mobile: {{ mobile_number }}</span><span v-if="ext_ref1"> (Ext Ref1: {{ ext_ref1 }})</span></div>
            </div>

            <div class="line">
                <div class="key">Address:</div>
                <div class="value">{{ fwadata.location.address || fwadata.location_address }}</div>
            </div>

            <div class="line">
                <div class="key">SIMID:</div>
                <div class="value">{{ simid }}</div>
            </div>

            <div class="line">
                <div class="key">MSISDN:</div>
                <div class="value">{{ msisdn }}</div>
            </div>

            <div class="line" v-if="selected_product">
                <div class="key">Product:</div>
                <div class="value">{{ selected_product.Description }}</div>
            </div>

            <!-- <div class="line">
                <div class="key">Price:</div>
                <div class="value">{{ price(selected_product.Cents) }}</div>
            </div> -->
        </div>

        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import dialogBroadbandAddSuccess from '../dialogs/dialog-broadband-add-success'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        fwadata: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,

        dialogBroadbandAddSuccess,
    },

    data() {
        return {
            loading: false,

            error: {
                show: false,
                message: '',
            },

            dialogs: {
                success: {
                    show: false,
                }
            },
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        customer_uuid() {
            return this.fwadata.customer.UUID
        },

        first_name() {
            return this.fwadata.customer.FirstName
        },

        last_name() {
            return this.fwadata.customer.LastName
        },

        email() {
            return this.fwadata.customer.Email
        },

        mobile_number() {
            return this.fwadata.customer.MobileNumber
        },

        ext_ref1() {
            return this.fwadata.customer.ExternalRef1
        },

        prequal_uuid() {
            return `${this.fwadata.location_provider}:${this.fwadata.location_provider_idx}`
        },

        simid() {
            return this.fwadata.plan.simid
        },

        msisdn() {
            return this.fwadata.plan.msisdn
        },

        selected_product_uuid() {
            return this.fwadata.plan.product ? this.fwadata.plan.product.UUID : ''
        },

        selected_product() {
            return this.fwadata.plan.product
        }
    },

    methods: {
        init() {},

        price(cents) {
            return `$${ ( cents / 100 ).toFixed(2) }`
        },

        confirm() {
            this.loading = true

            const fwaorder = {
                SPID: this.current_spid,
                CustomerUUID: this.customer_uuid,
                PlanUUID: this.selected_product_uuid,
                PrequalUUID: this.prequal_uuid,
                ServiceAddressID: this.prequal_uuid,
                SIMID: this.simid,
                MSISDN: this.msisdn,
                AutoAllocateMSISDN: (this.msisdn.length == 0),
                LocationAddress: this.fwadata.location_address,
            }

            this.$store.dispatch('api_fwa/CreateFWAProduct', fwaorder)
                .then(() => {
                    this.error.show = false
                    this.error.message = ''

                    this.loading = false

                    this.dialogs.success.show = true
                })
                .catch(error => {
                    this.error.show = true
                    this.error.message = errMessage(error)

                    this.loading = false
                })
        },

        onSuccessCloses() {
            this.$router.push({
                name: 'customer-products',
                params: {
                    uuid: this.customer_uuid,
                    type: 'fwa',
                }
            })
        },
    },
}
</script>

<style lang="scss">
.stage-summary {
    .app-error {
        margin-bottom: 30px;
    }

    .summary {
        margin-bottom: 24px;

        .line {
            display: flex;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .key {
                min-width: 130px;
                padding-right: 20px;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.33;
                color: var(--color-text-primary);
            }

            .value {
                font-size: 16px;
                line-height: 1.5;
                color: var(--color-text-primary);
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-summary {
        .summary {
            .line {
                flex-direction: column;
                font-size: 14px;
                margin-bottom: 8px;

                .key {
                    font-size: 16px;
                    padding: 0;
                    margin-bottom: 4px;
                }
            }
        }
    }
}
</style>