import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    simid: {
        name: 'SIMID',
        required: true,
        format: /^(\d{16}|\d{19})$/,
    },
    imsi: {
        name: 'IMSI',
        required: true,
        format: /^\d{15,}$/,
    },
    msisdn: {
        name: 'MSISDN',
        required: true,
        format: /^\d{12,}$/,
    },
    service_profile: {
        name: 'Service profile',
        required: true,
    },    product_catalog: {
        name: 'Product_catalog',
        required: true,
    },
})