<template>
    <div class="stage-configure-plan">
        <app-loader v-if="is_loading" />
        <app-error v-model="errors.show" :message="errors.message" />

        <div class="group">
            <div class="column">
                <div class="control">
                    <app-input
                        v-model="plan.simid"

                        label="SIM Card ID *"
                        type="tel"
                        placeholder="Please enter the 16 or 19 digit SIM Card ID"

                        :mask="simid_mask"

                        :error="errors.fields.simid"

                        @change="onSIMIDChange"
                    />
                </div>

                <div class="control">
                    <app-dropdown-select
                        v-model="plan.product_catalog"

                        label="Choose Product"

                        key-value="uuid"
                        key-title="title"

                        :options="product_catalog_options"
                        :error="errors.fields.product_catalog"

                        @change="changeProduct"
                    ></app-dropdown-select>
                </div>
            </div>

            <div class="column">
                <div class="control">
                    <button v-if="!product_catalog_options || (Object.keys(product_catalog_options).length == 0)" class="btn btn-primary btn-small" @click="getProductCatalogOptions">Reload Products</button>
                </div>
            </div>
        </div>

        <button class="btn btn-primary btn-small" :disabled="!is_sim_card_id_validation_successful || !is_plan_selected" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appLoader from '@/components/app-loader'
import appDropdownSelect from '@/components/app-dropdown-select'

import FixedWirelessValidator from '@/validators/fixed-wireless-validator'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appError,
        appInput,
        appLoader,
        appDropdownSelect,
    },

    props: {
        fwadata: { type: Object, required: true },
    },

    data() {
        return {
            loading: {
                product_catalog_options: false,

                sim_card_id_validation: false,
            },

            plan: {
                simid: '',
                product: {},
                product_catalog: '',
            },

            is_sim_card_id_validation_successful: false,

            product_catalog_options: [],

            errors: {
                show: false,
                message: '',

                fields: {
                    simid: '',
                    product_catalog: '',
                },
            },
        }
    },

    methods: {
        init() {
            this.plan = {
                ...this.plan,
                ...this.fwadata.plan,
            }

            this.getProductCatalogOptions()
        },

        changeProduct(product) {
            this.plan.product = this.product_catalog_options[product]
        },

        getProductCatalogOptions() {
            this.loading.product_catalog_options = true

            const params = {
                SPID: this.current_spid,
                Location: this.prequal_uuid,
            }

            this.$store.dispatch('api_fwa/FindFWAPlansPaginated', params)
                .then(({ FWAPlans }) => {
                    let plans = {}

                    if (FWAPlans) {
                        for (let i = 0, len = FWAPlans.length; i < len; i++) {
                            plans[FWAPlans[i].UUID] = {
                                ...FWAPlans[i],
                                uuid: FWAPlans[i].UUID,
                                title: `${ FWAPlans[i].Name } ($${ FWAPlans[i].Cents % 100 == 0 ? FWAPlans[i].Cents / 100 : (FWAPlans[i].Cents / 100).toFixed(2) })`,
                            }
                        }
                    }

                    this.product_catalog_options = plans
                    this.loading.product_catalog_options = false
                })
                .catch(error => {
                    console.error(error)

                    this.loading.product_catalog_options = false
                })
        },

        validateSIMCardID() {
            this.loading.sim_card_id_validation = true

            const params = {
                SPID: this.current_spid,
                SIMID: this.plan.simid,
            }

            this.$store.dispatch('api_simdb/FindSIMSPaginated', params)
                .then(({ SIMS }) => {
                    this.errors.show = false
                    this.errors.message = ''

                    if (SIMS.length == 0) {
                        this.is_sim_card_id_validation_successful = false

                        this.errors.show = true
                        this.errors.message = 'That SIMID is not available'
                    } else {
                        const sim = SIMS[0]

                        if (sim.Active) {
                            this.is_sim_card_id_validation_successful = false

                            this.errors.show = true
                            this.errors.message = `That SIMID is already in use by service ${ sim.ServiceInstanceUUID }`
                        } else {
                            this.is_sim_card_id_validation_successful = true
                        }
                    }

                    this.loading.sim_card_id_validation = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.is_sim_card_id_validation_successful = false

                    this.loading.sim_card_id_validation = false
                })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                simid:           { rule: 'simid',           message: 'Please enter the 16 or 19 digit SIM Card ID' },
                product_catalog: { rule: 'product_catalog', message: 'Please choose product'      },
            }

            for (const key in fields) {
                if (FixedWirelessValidator.isRuleExists(fields[key].rule)) {
                    if (FixedWirelessValidator.isInvalid(fields[key].rule, this.plan[key], fields[key].message)) {
                        this.errors.fields[key] = FixedWirelessValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                const plan = {
                    ...this.plan
                }

                this.$emit('ready', { plan })
                this.$emit('go-next')
            }
        },

        onSIMIDChange() {
            const field = {
                rule: 'simid',
                value: this.plan.simid,
                message: 'Please enter the 16 or 19 digit SIM Card ID',
            }

            if (FixedWirelessValidator.isInvalid(field.rule, field.value, field.message)) {
                this.errors.fields[field.rule] = FixedWirelessValidator.getError()
            } else {
                this.$delete(this.errors.fields, field.rule)

                this.validateSIMCardID()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        is_loading() {
            return this.loading.product_catalog_options || this.loading.sim_card_id_validation
        },

        is_plan_selected() {
            return (this.plan && this.plan.product && this.plan.product.UUID && this.plan.product_catalog) ? true : false
        },

        prequal_uuid() {
            return `${this.fwadata.location_provider}:${this.fwadata.location_provider_idx}`
        },

        simid_mask() {
            return {
                mask: '9999999999999999999',
                showMaskOnHover: false,
            }
        },
    },
}
</script>

<style lang="scss">
.stage-configure-plan {
    .app-error {
        margin-bottom: 24px;
    }

    .group {
        display: flex;
        margin-bottom: 30px;

        .column {
            width: 100%;
            max-width: 470px;
            margin-right: 30px;

            .control {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-configure-plan {
        .group {
            flex-direction: column;
            margin: 0;

            .column {
                max-width: 100%;
                margin-bottom: 24px;

                .control {
                    margin-bottom: 24px;
                }
            }
        }
    }
}
</style>