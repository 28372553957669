<template>
    <app-dialog class="dialog-bb-thank"
        v-if="show"
        v-model="show"

        :max-width="maxWidth"
        :mobile-view="mobileView"

        :closable="false"

        @closes="closes"

        ref="dialog"
    >
        <template #head>
            <div class="head">
                <slot name="head">
                    <h3>Thank you!</h3>
                </slot>
            </div>
        </template>

        <template #form>
            <p>We will contact you soon.</p>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click="close">Ok</button>
        </template>
    </app-dialog>
</template>

<script>
import appDialog from '@/components/app-dialog'

import BroadbandValidator from '@/validators/broadband-validator'

export default {
    props: {
        value: { required: true },

        maxWidth: { type: [Number, String], default: 540 },
        mobileView: { type: String, default: null }, // null | `full` | `bottom`
    },

    components: {
        appDialog,
    },

    methods: {
        closes(with_button) {
            this.$emit('closes', with_button)
        },

        close() {
            this.$refs.dialog.close()
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog.dialog-bb-thank {
    .app-dialog-body {
        padding: 80px 24px 62px;

        h3 {
            margin: 0;
        }

        form {
            margin: 46px 0 0;

            p {
                font-size: 18px;
                line-height: 20px;
                font-weight: 300;
                text-align: center;
            }
        }

        .btns {
            margin-top: 46px;

            .btn {
                width: 245px;
                height: 48px;
                border-radius: $border-radius-secondary;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog.dialog-bb-thank {
        .app-dialog-body {
            padding: 24px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            form {
                margin-top: 32px;

                p {
                    font-size: 16px;
                }
            }

            .btns {
                margin-top: 32px;

                .btn {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>