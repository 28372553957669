<template>
    <div class="stage-location">
        <section class="location">
            <app-error v-model="errors.show" :message="errors.message"></app-error>

            <form @submit.prevent class="form-location">
                <app-input-location-autocomplete v-if="search_by_locationsvc"
                    v-model="location.address"

                    :debounce="250"
                    :with-manually="false"

                    label="Fixed Wireless Service Address"
                    :disabled="processing"
                    :with-location-providers="fwa_search_providers"

                    :error="!!errors.fields.location"

                    @change="resetAvailable"
                    @change-place="onChangePlace"

                    @enter-manually="dialogs.location.show = true"

                    ref="location"
                ></app-input-location-autocomplete>

                <app-input-google-autocomplete-manually v-else
                    v-model="location.address"

                    :with-manually="false"

                    label="Fixed Wireless Service Address"
                    :disabled="processing"

                    :error="!!errors.fields.location"

                    @change="resetAvailable"
                    @change-place="onChangePlace"
                    @change-address="checkAvailability"

                    @enter-manually="dialogs.location.show = true"

                    ref="location"
                ></app-input-google-autocomplete-manually>
            </form>
        </section>

        <div v-if="serviceAbilityMessage" class="services">
            <div class="prefix">Service Availability:</div>
            <div class="list">{{ serviceAbilityMessage }}</div>
        </div>

        <section class="location-variants" v-if="location.available !== false && location.variants.list">
            <h5>Please select your location:</h5>

            <app-radio-buttons
                v-model="location.variants.checked"
                
                :items="location.variants.list"
                :return-item="true"

                key-value="Formatted"
                key-label="Formatted"

                @select="onLocationSelected"
            ></app-radio-buttons>
        </section>

        <button class="btn btn-primary btn-small btn-confirm" @click="confirm" :disabled="disabled || !serviceableFWA">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'

import appInputGoogleAutocompleteManually from '@/components/app-input-google-autocomplete-manually'
import appInputLocationAutocomplete from '@/components/app-input-location-autocomplete'

import appRadioButtons from '@/components/app-radio-buttons'

import dialogThank from '@/views/fibre/add-service/dialogs/dialog-thank'

import BroadbandValidator from '@/validators/broadband-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        fwadata: { type: Object, required: true },
    },

    components: {
        appError,

        appInputGoogleAutocompleteManually,
        appInputLocationAutocomplete,

        appRadioButtons,

        dialogThank,
    },

    data() {
        return {
            disabled: true,
            serviceAbilityMessage: '',
            serviceableFWA: false,

            location: {
                LocationProvider: '',
                LocationProviderIDX: '',

                address: '',
                location: {},
                prediction: {},

                variants: {
                    list: null,
                    checked: null,
                },

                available: null,
            },

            contacts: {
                user: {
                    name: process.env.VUE_APP_TEST_CUSTOMER_FIRSTNAME || '',
                    email: process.env.VUE_APP_TEST_CUSTOMER_EMAIL || '',
                    phone: process.env.VUE_APP_TEST_CUSTOMER_PHONE || '',
                },

                comment: '',
            },

            dialogs: {
                thank: {
                    show: false,
                },
            },

            errors: {
                show: false,
                message: '',
                fields: {},
            },

            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.location = {
                ...this.location,
                ...this.fwadata.location
            }
        },

        confirm() {
            this.$emit('ready', { location: this.location })
            this.goNext()
        },

        goNext() {
            this.processing = true

            this.$store.dispatch('saveBroadbandLocation', { address: this.location.address }).then(() => {
                this.$emit('go-next')
                this.processing = false
            }).catch(error => {
                this.errors.show = true
                this.errors.message = errMessage(error)
                this.processing = false
            })
        },

        resetAvailable() {
            this.location.available = null
            this.location.variants = {
                list: null,
                checked: null,
            }
            this.serviceAbilityMessage = ''

            this.$emit('reset')
        },

        onChangePlace(address, prediction) {
            this.location.address = address
            this.location.prediction = prediction

            if (this.search_by_locationsvc) {
                this.onLocationSelected(prediction)
            }
        },

        onAddressEnter(address) {
            this.serviceAbilityMessage = '';
            this.location.address = address
            this.checkAvailability()
        },

        checkAvailability() {
            this.serviceAbilityMessage = '';

            if (this.validation('location')) {
                this.processing = true

                this.resetAvailable()

                if (this.search_by_locationsvc) {
                    const payload = {
                        addresstext: this.location.address,
                    }

                    this.$store.dispatch('searchBroadbandLocation', payload).then(locations => {

                        // if (locations.length > 1) {
                            this.location.variants.list = locations
                        // } else {
                        //     this.location.available = true

                        //     this.$nextTick(() => {
                        //         this.disabled = false
                        //     })
                        // }

                        this.processing = false
                    }).catch(error => {
                        this.location.available = false

                        this.processing = false
                    })
                } else {
                    const address = this.location.address
                    this.$store.dispatch('checkBroadbandAvailable', { address }).then(locations => {
                        if (locations.length > 1) {
                            this.location.variants.list = locations
                            this.disabled = true
                        } else {
                            this.location.available = true

                            this.$nextTick(() => {
                                this.disabled = false
                            })
                        }

                        this.processing = false
                    }).catch(error => {
                        this.location.available = false

                        this.processing = false
                    })
                }
            }
        },

        onLocationSelected(location) {
            this.serviceAbilityMessage = ''

            if (this.search_by_locationsvc) {
                this.processing = true

                const payload = {
                    SPID: this.current_spid,
                    LocationProvider: location.LocationProvider,
                    LocationProviderIDX: location.LocationProviderIDX,
                }
                this.serviceAbilityMessage = 'Please wait, checking...'
                this.$store.dispatch('api_location/GetServiceAvailability', payload).then(apidata => {

                    this.location.LocationProvider = apidata.Location && apidata.Location.LocationProvider ? apidata.Location.LocationProvider : ''
                    this.location.LocationProviderIDX = apidata.Location && apidata.Location.LocationProviderIDX ? apidata.Location.LocationProviderIDX : ''

                    this.location.address = location.Formatted
                    this.location.location = location
                    this.location.available = true

                    // Check if FWA is available
                    let isFWAServiceable = false
                    for (let i = 0; i < apidata.Services.length; i++) {
                        if (apidata.Services[i].AccessType && (apidata.Services[i].AccessType == "4G_HWBB" || apidata.Services[i].AccessType == "FWA")) {
                            if (apidata.Services[i].ServiceabilityType && (apidata.Services[i].ServiceabilityType == "serviceable")) {
                                isFWAServiceable = true
                                break
                            }
                        }
                    }
                    if (isFWAServiceable) {
                        this.serviceAbilityMessage = 'Serviceable'
                        this.serviceableFWA = true

                        this.fwadata.location_provider = this.location.LocationProvider
                        this.fwadata.location_provider_idx = this.location.LocationProviderIDX

                        this.fwadata.location_address = this.location.address

                        this.$nextTick(() => {
                            this.disabled = false
                        })
                    } else {
                        this.disabled = true
                        this.serviceAbilityMessage = 'No FWA service available'
                        this.serviceableFWA = false
                    }

                    this.processing = false
                }).catch(error => {
                    this.serviceAbilityMessage = error && error.response && error.response.status == 404
                        ? 'No FWA services available'
                        : errMessage(error)
                    this.serviceableFWA = false
                    
                    this.location.available = false
                    this.disabled = true
                    this.processing = false
                })

            } else {
                this.location.address = location.Formatted
                this.location.available = true

                this.fwadata.location_provider = this.location.LocationProvider
                this.fwadata.location_provider_idx = this.location.LocationProviderIDX

                this.fwadata.location_address = this.location.address

                this.$nextTick(() => {
                    this.disabled = false
                })
            }
        },

        validation(target) {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',
                fields: {},
            }

            let values = null

            switch (target) {
                case 'location': {
                    values = {
                        location: this.location.address,
                    }
                } break

                case 'contacts': {
                    values = {
                        name: this.contacts.user.name,
                        email: this.contacts.user.email,
                        phone: this.contacts.user.phone,
                    }
                }
            }

            for (const key in values) {
                if (BroadbandValidator.isRuleExists(key)) {
                    if (BroadbandValidator.isInvalid(key, values[key])) {
                        this.errors.fields[ key ] = BroadbandValidator.getError()
                    }
                }
            }

            return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[ errors[0] ]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    watch: {
        location: {
            handler() {
                if (this.location.available) {
                    // this.$emit('ready', { location: this.location })
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            },
            deep: true,
        },

        processing() {
            this.$emit('processing', this.processing)
        },
    },

    computed: {
        ...mapGetters({
            'current_spid': 'current_spid',
            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
            'fwa_search_providers': 'fwa_search_providers',
        }),
    },
}
</script>

<style lang="scss">
.stage-location {
    .location {
        width: 100%;
        max-width: 657px;

        form {
            display: flex;
            justify-content: center;
            align-items: center;

            .app-input-google-autocomplete-manually,
            .app-input-location-autocomplete {
                border-radius: $border-radius-secondary;

                input {
                    border-radius: $border-radius-secondary;
                }
            }
        }
    }

    .services {
        display: flex;
        width: 100%;
        max-width: 660px;
        margin-top: 16px;
        line-height: 24px;
        font-size: 16px;

        .prefix {
            padding-right: 32px;
            font-size: 18px;
            font-weight: bold;
            color: var(--color-text-primary);
        }

        .list {
            color: var(--color-text-primary);
        }
    }

    .location-variants {
        margin-top: 35px;

        h5 {
            font-size: 28px;
            line-height: 32px;
        }

        .app-radio-buttons {
            padding: 16px 0;
            background: transparent;

            input[type=radio] {
                &+label {
                    margin: 0;
                    padding: 7px 0;

                    &>.label {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .btn-confirm {
        margin-top: 24px;
    }
}

@media (max-width: $mobile-size) {
    .stage-location {
        .services {
            flex-direction: column;
            font-size: 14px;

            .prefix {
                font-size: 16px;
                padding: 0;
            }

            .list {
                margin-top: 4px;
            }
        }

        .location-variants {
            margin-top: 24px;

            h5 {
                font-size: 24px;
                line-height: 32px;
            }

            .app-radio-buttons {
                input[type=radio] {
                    &+label {
                        &>.label {
                            font-size: 16px;
                        }
                    }
                }
            }

            &+.actions {
                margin-top: 24px;
            }
        }
    }
}
</style>