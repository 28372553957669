<template>
    <base-layout>

        <div class="container page-fwa-fixed-wireless-stages">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Add Fixed Wireless Access</h1>

                    <app-loader v-if="loading"></app-loader>

                    <div class="fixed-wireless-stages">
                        <h3>Fixed Wireless Stages</h3>

                        <div class="stages">
                            <div class="stage" v-for="(stage, key) in stages.list" :key="key"
                                :class="{
                                    [`step-${ key }`]: true,
                                    opened: stage.name == stages.opened,
                                    completed: stage.completed,
                                    current: stage.name == stages.current,
                                }"
                            >
                                <div class="stage-head">
                                    <div class="btn btn-stage btn-stage-point">
                                        <div class="stage-circle">
                                        </div>
                                        <span></span>
                                    </div>
                                    <button class="btn btn-stage btn-stage-expand" @click="toggleStage(stage)" :disabled="!stage.ready">{{ stage.title }}</button>
                                </div>

                                <div class="stage-body">
                                    <component :is="stage.body"
                                        :fwadata="fwadata"

                                        @ready="onStageReady"
                                        @reset="onStageReset(stage)"
                                        @go-next="goNext"

                                        @confirm="onConfirm"

                                        ref="stage"
                                    ></component>
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-error v-model="error.show" :message="error.message"></app-error>
                </div>
            </div>
        </div>

        <dialog-broadband-add-success
            v-if="dialogs.success.show"
            v-model="dialogs.success.show"

            @closes="onSuccessCloses"
        ></dialog-broadband-add-success>

    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import errMessage from '@/helpers/errMessage'

import dialogBroadbandAddSuccess from './dialogs/dialog-broadband-add-success'

import stageCustomerDetails from '@/components/app-stage-customer-details'
import stageAddressSearch from './stages/address-search'
import stageConfigurePlan from './stages/configure-plan'
import stageSummary from './stages/summary'

import { mapGetters } from 'vuex'

export default {
    components: {
        baseLayout,
        appLoader,
        appError,
        dialogBroadbandAddSuccess,
    },

    data() {
        return {
            dialogs: {
                success: {
                    show: false,
                }
            },

            error: {
                show: false,
                message: '',
            },

            loading: false,

            data: {
                customer_details: {
                    account_id: null,

                    first_name: null,
                    last_name: null,
                },
                
                address_search: {
                    address: null,
                    services: [],
                },

                configure_plan: {
                    simid: null,
                    msisdn: null,

                    service_profile: {
                        value: '',
                        title: '',
                    },

                    product: {
                        value: '',
                        title: '',
                    },
                },
            },

            fwadata: {
                customer: {},
                plan: {
                    simid: '',
                    msisdn: 'auto allocated',

                    service_profile: '30 Down / 15 Up',
                    product_catalog: '35882e56-70f2-450b-b41d-c1baaac8a618',
                },

                customer_uuid: '',
                new_customer: {
                    first_name: '',
                    last_name: '',
                    mobile: '',
                    email: '',
                    extref1: '',
                    extref2: '',
                    extref3: '',
                    extref4: '',
                    extref5: '',
                },
                location: {},
                location_address: '',
                location_provider: '',
                location_provider_idx: '',
                package: null,

                simid: '',
                msisdn: '',

                serviceprofile: '30 Down / 15 Up',
                serviceprofile_name: '30Mbps Down / 15Mbps Up',

                product_uuid: '35882e56-70f2-450b-b41d-c1baaac8a618',
                product_name: 'FWA Test Product',
            },

            stages: {
                opened: 'address-search',
                current: 'address-search',

                list: {
                    'address-search': {
                        name: 'address-search',
                        ready: true,
                        completed: false,
                        title: 'Address search',
                        body: stageAddressSearch,

                        reset: {
                            fwadata: {
                                plan: {
                                    product: {},
                                    product_catalog: '',
                                },
                            }
                        },
                    },

                    'customer-details': {
                        name: 'customer-details',
                        ready: false,
                        completed: false,
                        title: 'Customer details',
                        body: stageCustomerDetails,
                    },

                    'configure-plan': {
                        name: 'configure-plan',
                        ready: false,
                        completed: false,
                        title: 'Configure plan',
                        body: stageConfigurePlan,
                    },

                    'summary': {
                        name: 'summary',
                        ready: false,
                        completed: false,
                        title: 'Summary',
                        body: stageSummary,
                    },
                },
            },
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        stage() {
            return this.stages.list[ this.stages.opened ]
        },

        stages_indexes() {
            let index = 0,
                indexes = {}

            for (const key in this.stages.list) {
                indexes[ key ] = index++
            }

            return indexes
        },

        stages_sequence() {
            return Object.keys(this.stages.list)
        },
    },

    methods: {
        goNext() {
            this.stage.completed = true

            const next_stage_index = this.stages_indexes[this.stages.opened] + 1

            if (next_stage_index < this.stages_sequence.length) {
                this.stages.opened = this.stages_sequence[ next_stage_index ]
                this.stages.current = this.stages.opened

                this.$nextTick(() => {
                    this.$refs.stage[next_stage_index].init()
                })
            }
        },

        onStageReady(fwadata) {
            if (fwadata) {
                this.fwadata = {
                    ...this.fwadata,
                    ...fwadata,
                }

                this.toggleStageReady(true)
            } else {
                switch (this.step.name) {

                    /* @todo ??? */
                    case 'address-search': {
                        this.fwadata.location = {}
                    } break

                    case 'configure-plan': {
                        this.fwadata.package = null
                    } break
                    /* @todo ??? */
                }

                this.toggleStageReady(false)
            }
        },

        onStageReset(stage) {
            console.warn('onStageReset')
            console.log('this.fwadata', {...this.fwadata})
            if (stage.completed && 'reset' in stage) {
                this.stages.current = stage.name

                let reset_stages = false
                for (const key in this.stages.list) {
                    if (reset_stages) {
                        this.stages.list[key].ready = false
                        this.stages.list[key].completed = false
                    } else if (this.stages.list[key].name == stage.name) {
                        reset_stages = true
                        this.stages.list[key].completed = false
                    }
                }

                if (stage.reset
                    && typeof stage.reset == 'object'
                    && stage.reset.fwadata
                ) {
                    const reset_fwadata = (fwadata, data) => {
                        for (const key in data) {
                            if (key in fwadata) {
                                if (data[key] == null) {
                                    fwadata[key] = data[key]
                                } else if (Array.isArray(data[key])) {
                                    fwadata[key] = [...data[key]]
                                } else if (typeof data[key] == 'object') {
                                    if (Object.keys(data[key]).length) {
                                        reset_fwadata(fwadata[key], data[key])
                                    } else {
                                        fwadata[key] = {...data[key]}
                                    }
                                } else {
                                    fwadata[key] = data[key]
                                }
                            }
                        }
                    }
                    reset_fwadata(this.fwadata, stage.reset.fwadata)
                }
            }
        },

        toggleStageReady(ready) {
            if (ready) {
                const next_stage = this.stages_sequence[ this.stages_indexes[this.stages.opened] + 1 ]

                if (next_stage) {
                    this.stages.list[ next_stage ].ready = true
                }
            } else {
                /*
                    for (let i = this.stages_indexes[this.stages.opened] + 1, len = this.stages_sequence.length; i < len; i++) {
                        const next_stage = this.stages_sequence[i]

                        this.stages.list[ next_stage ].ready = false

                        this.stages.current = this.stages.opened
                    }
                */
            }
        },

        onChangeProcessing(processing) {
            this.processing = processing
        },

        toggleStage(stage, is_not_closed) {
            if (is_not_closed) {
                this.stages.opened = stage.name
            } else {
                this.stages.opened = this.stages.opened == stage.name ? null : stage.name
            }
        },

        onConfirm() {
            this.loading = true

            const fwaorder = {
                SPID: this.current_spid,
                CustomerUUID: this.fwadata.customer_uuid,
                ReferenceCode: '',
                ServiceProfile: this.fwadata.serviceprofile,
                SIMID: this.fwadata.simid,
                MSISDN: this.fwadata.msisdn,
                ProductCatalogUUID: this.fwadata.product_uuid,
            }

            this.$store.dispatch('fwa/OrderFWA', fwaorder).then(resp => {
                console.log('Order FWA OK:')
                console.log(resp)

                this.error.show = false
                this.error.message = ''

                this.loading = false

                this.dialogs.success.show = true
            }).catch(error => {

                this.error.show = true
                this.error.message = errMessage(error)

                this.loading = false

                console.log('Error OrderFWA', fwaorder)
                console.error(error)
            })
        },

        onSuccessCloses() {
            this.$router.push({ name: 'fwa-services' })
        },
    },
}
</script>

<style lang="scss">
.page-fwa-fixed-wireless-stages {
    h1 {
        span {
            white-space: nowrap;
        }
    }

    .fixed-wireless-stages {
        position: relative;
        margin: 48px auto;
        padding: 24px 24px 32px;
        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-tertiary);

        /* @todo remove this "overflow: hidden;" after making for :before dynamic height */
        overflow: hidden;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
        }

        .stages {
            margin: 32px 0 0 8px;
            border-radius: 0 0 $border-radius-secondary $border-radius-secondary;

            /* @todo make for :before dynamic height */
            // overflow: hidden;

            .btn-stage {
                padding: 0;

                &:not(button) {
                    cursor: default;
                }

                &.btn-stage-point {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    background: transparent;
                    flex-shrink: 0;
                    margin-right: 16px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 1000px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: var(--color-stages-icons-bg);
                        transform: translate(-50%, 0);
                        pointer-events: none;
                    }

                    .stage-circle {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0.5);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100%;
                        height: 100%;

                        background-color: var(--color-stages-icons-bg);

                        border: 2px solid var(--color-stages-icons-bg);

                        border-radius: 50%;

                        pointer-events: none;

                        transition: $transition-duration-primary;

                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        width: 24px;
                        height: 24px;

                        color: var(--color-stage-icon);

                        opacity: 0;
                        visibility: hidden;

                        transition: $transition-duration-primary;
                    }

                    // &:after {
                    //     content: "";
                    //     display: inline-block;
                    //     width: 50%;
                    //     height: 50%;
                    //     border: 2px solid var(--color-stages-icons-bg);
                    //     border-radius: 50%;
                    //     background: var(--color-stages-icons-bg) none no-repeat center;
                    //     box-sizing: border-box;
                    //     position: absolute;
                    //     top: 50%;
                    //     left: 50%;
                    //     transform: translate(-50%, -50%);
                    //     pointer-events: none;
                    //     transition: $transition-duration-primary;
                    // }

                    &.horizontal {
                        &:before {
                            width: 1000px;
                            height: 2px;
                            transform: translate(0, -50%);
                        }
                    }

                    &[disabled] {
                        opacity: 1;
                    }
                }

                &.btn-stage-expand {
                    width: auto;
                    height: 100%;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--color-stages-title);
                    text-transform: $stages-btn-text-transform;
                    transition: $transition-duration-primary;

                    &[disabled] {
                        color: var(--color-stages-title);
                        cursor: default;
                    }
                }
            }

            .stage {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;

                    .btn-stage-point {
                        &:before {
                            background: var(--color-component-bg-primary);
                        }
                    }
                }

                .stage-head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    i {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        background: none no-repeat center;
                        background-size: contain;
                        flex-shrink: 0;
                    }
                }

                .stage-body {
                    display: none;
                    padding: 24px 0 0 #{ 32px + 16px };
                }

                &.opened {
                    .stage-head {
                        .btn-stage-expand {
                            color: var(--color-stages-title-active);

                            &:after {
                                transform: rotateX(180deg);
                            }
                        }
                    }

                    .stage-body {
                        display: block;
                    }
                }

                &.completed {
                    .btn-stage {
                        &.btn-stage-point {
                            &:before {
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }

                        &.btn-stage-expand {
                            &:hover {
                                color: var(--color-stages-title-active);
                            }
                        }
                    }
                }

                &.current {
                    .btn-stage {
                        &.btn-stage-point {
                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }
                        
                        &.btn-stage-expand {
                            color: var(--color-stages-title-active);
                        }
                    }
                }
            }

            .stage {
                &.step-address-search {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-map-mark);
                                }
                            }
                        }
                    }
                }

                &.step-configure-plan {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-plan);
                                }
                            }
                        }
                    }
                }

                &.step-summary {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-summary);
                                }
                            }
                        }
                    }
                }

                &.step-customer-details {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-add-customer);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-fwa-fixed-wireless-stages {
        .fixed-wireless-stages {
            padding: 24px 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fwa-fixed-wireless-stages {
        .fixed-wireless-stages {
            margin-top: 32px;
            padding: 16px 16px 16px 8px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            .stages {
                margin: 20px 0 0 0;

                .btn-stage {
                    &.btn-stage-point {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;

                        span {
                            transform: translate(-50%, -50%) scale(0.8);
                        }

                        // &:after {
                        //     background-size: 70%;
                        // }
                    }

                    &.btn-stage-expand {
                        font-size: 16px;
                    }
                }

                .stage {
                    .stage-body {
                        padding-left: 26px;

                        .app-input {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>