<template>
    <div class="stage-new-customer">
        <app-loader v-if="loading" />

        <app-error v-model="errors.show" :message="errors.message" />

        <div>
            <app-input v-model="customer.first_name"    label="First Name"      :required="!have_external_ref" type="text"  :error="errors.first_name"    @change="errors.first_name = null"    @onenter="confirm"                ></app-input>
            <app-input v-model="customer.last_name"     label="Last Name"       :required="!have_external_ref" type="text"  :error="errors.last_name"     @change="errors.last_name = null"     @onenter="confirm"                ></app-input>
            <app-input v-model="customer.mobile_number" label="Mobile number"   :required="!have_external_ref" type="tel"   :error="errors.mobile_number" @change="errors.mobile_number = null" @onenter="confirm"  mask="999999999999"></app-input>
            <app-input v-model="customer.email"         label="E-mail"          :required="!have_external_ref" type="email" :error="errors.email"         @change="errors.email = null"         @onenter="confirm" :maxlength="60"></app-input>

            <app-input v-model="customer.extref1"       label="External Ref# 1" :required="!have_customer_details" type="text"  :error="errors.extref1"       @change="errors.extref1 = null"       @onenter="confirm" :maxlength="80"></app-input>
            <app-input v-model="customer.extref2"       label="External Ref# 2"                                    type="text"  :error="errors.extref2"       @change="errors.extref2 = null"       @onenter="confirm" :maxlength="80"></app-input>
        </div>

        <button class="btn btn-primary btn-small" :disabled="is_disabled" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    components: {
        appInput,
        appLoader,
        appError,
    },

    data() {
        return {
            customer: {
                first_name:    '',
                last_name:     '',
                mobile_number: '',
                email:         '',

                extref1: '',
                extref2: '',
            },

            errors: {
                show: false,
                message: '',
            },

            loading: false,
        }
    },

    methods: {
        confirm() {
            if (this.validation()) {
                this.loading = true

                const payload = {
                    Active:       true,
                    SPID:         this.current_spid,
                    FirstName:    this.customer.first_name,
                    LastName:     this.customer.last_name,
                    Email:        this.customer.email,
                    MobileNumber: this.customer.mobile_number,
                    ExternalRef1: this.customer.extref1,
                    ExternalRef2: this.customer.extref2,
                }

                this.$store.dispatch('api_customer/AddCustomer', payload)
                    .then(customer => {
                        this.$emit('set-customer', customer)

                        this.errors = {
                            show: false,
                            message: '',
                        }

                        this.loading = false
                    })
                    .catch(error => {
                        this.errors = {
                            show: true,
                            message: errMessage(error),
                        }

                        this.loading = false
                    })
            }
        },
        
        validation() {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',
            }

            const fields = [
                {
                    rule: 'firstname',
                    message: 'Please provide a valid first name',
                    value: this.customer.first_name,
                    key: 'first_name',
                    required: !this.have_external_ref,
                },
                {
                    rule: 'lastname',
                    message: 'Please provide a valid last name',
                    value: this.customer.last_name,
                    key: 'last_name',
                    required: !this.have_external_ref,
                },
                {
                    rule: 'phone',
                    message: /[a-z]/i.test(this.customer.mobile_number) ? 'Alphabets are not allowed' : 'Please provide a valid mobile number',
                    value: this.customer.mobile_number,
                    key: 'mobile_number',
                    required: !this.have_external_ref,
                },
                {
                    rule: 'email',
                    message: 'Please provide a valid email',
                    value: this.customer.email,
                    key: 'email',
                    required: !this.have_external_ref,
                },
                {
                    rule: 'extref1',
                    message: 'Please provide a valid External reference 1',
                    value: this.customer.extref1,
                    key: 'extref1',
                    required: !this.have_customer_details,
                },
            ]

            for(let field of fields) {
                if (UserDetailsValidator.isRuleExists(field.rule)) {
                    if (field.required || field.value) {
                        if (UserDetailsValidator.isInvalid(field.rule, field.value, field.message)) {
                            this.errors[field.key] = UserDetailsValidator.getError()
                            is_valid = false
                        }
                    }
                }
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        have_external_ref() {
            return this.customer.extref1.length > 0
        },

        have_customer_details() {
            return this.customer.first_name.length > 0
                && this.customer.last_name.length > 0
                && this.customer.mobile_number.length > 0
                && this.customer.email.length > 0
        },

        is_disabled() {
            /**
             * Either External Reference 1 or Customer details are required
             */
            return !this.have_customer_details && !this.have_external_ref
        },
    }
}
</script>

<style lang="scss">
.stage-new-customer {
    padding: 30px 0;

    .app-error {
        margin-bottom: 24px;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .app-input {
            width: calc(100% / 3 - 20px);
            
            &:nth-child(n + 4) {
                margin-top: 30px;
            }
        }
    }

    .btn-primary {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .stage-new-customer {
        & > div {
            .app-input {
                width: calc(100% / 2 - 15px);

                &:nth-child(n + 3) {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-new-customer {
        padding: 24px 0;

        & > div {
            flex-direction: column;

            .app-input {
                width: 100%;
                margin-top: 24px;
                
                &:nth-child(n + 3) {
                    margin-top: 24px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .btn-primary {
            margin-top: 24px;
        }
    }
}
</style>